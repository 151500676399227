export var contactLinks = {
  twitter: 'https://twitter.com/republiccrypto',
  linkedin: 'https://www.linkedin.com/company/republiccrypto',
  email: 'mailto:gm@republiccrypto.com ',
  discord: 'https://discord.gg/BFuanCVFKx',
  substack: 'https://republiccrypto.substack.com',
  republicGroup: 'https://group.republic.com/',
  republic: 'https://republic.com',
  republicCapital: 'https://www.republiccapital.co/',
  linkedinJobs: 'https://www.linkedin.com/jobs/republic-crypto-jobs-worldwide'
};
export var variants = {
  hidden: {
    opacity: 0
  },
  enter: {
    opacity: 1,
    transition: {
      duration: 1
    }
  },
  exit: {
    opacity: 0
  }
};
export var containerVariants = {
  offscreen: {
    opacity: 0
  },
  onscreen: {
    opacity: 1,
    transition: {
      duration: 1.5,
      staggerChildren: 0.2
    }
  }
};