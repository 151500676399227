import _slicedToArray from "/runner/_work/r-client/r-client/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/slicedToArray.js";
import { useState } from 'react';
import { getWindow } from '@r-client/shared/util/core';
export function ViewportPosition(elementID) {
  var wnd = getWindow();

  var _useState = useState(false),
      value = _useState[0],
      setValue = _useState[1];

  if (wnd) {
    var el = document.querySelector(elementID);
    var observer = new wnd.IntersectionObserver(function (_ref) {
      var _ref2 = _slicedToArray(_ref, 1),
          entry = _ref2[0];

      if (entry.isIntersecting) {
        setValue(true);
        return;
      }

      setValue(false);
    }, {
      root: null,
      threshold: 0.6 // set offset 0.1 means trigger if atleast 10% of element in viewport

    });
    el && observer.observe(el);
  }

  return value;
}